#mapId {
    height: 100vh;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.minimizedRouting {
    display: none;
}

.leaflet-routing-container {
    width: 100%;
}

/*override css leaflet routing */
.leaflet-routing-geocoders input {
    width: calc(100% - 10px);
}

.icon-cluster {
    font-size: 12px;
    font-weight: bold;
    line-height: 56px !important;
    height: 56px !important;
    width: 56px !important;
    text-align: center;
    color: #000000;
    background: url('./_assets/images/cluster.png') repeat scroll 0 0 rgba(0, 0, 0, 0);
    margin-top: -28px;
    margin-left: -28px;
}


/*
SPRITE CSS RED CAR*/
.redcar-sprite-car_1 {
    background: url('./_assets/images/css-sprite-combined.png') 0px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_2 {
    background: url('./_assets/images/css-sprite-combined.png') -60px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_3 {
    background: url('./_assets/images/css-sprite-combined.png') -120px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_4 {
    background: url('./_assets/images/css-sprite-combined.png') -180px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_5 {
    background: url('./_assets/images/css-sprite-combined.png') -240px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_6 {
    background: url('./_assets/images/css-sprite-combined.png') -300px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_7 {
    background: url('./_assets/images/css-sprite-combined.png') -360px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_8 {
    background: url('./_assets/images/css-sprite-combined.png') -420px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_9 {
    background: url('./_assets/images/css-sprite-combined.png') -480px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_10 {
    background: url('./_assets/images/css-sprite-combined.png') -540px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_11 {
    background: url('./_assets/images/css-sprite-combined.png') -600px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_12 {
    background: url('./_assets/images/css-sprite-combined.png') -660px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_13 {
    background: url('./_assets/images/css-sprite-combined.png') -720px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_14 {
    background: url('./_assets/images/css-sprite-combined.png') -780px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_15 {
    background: url('./_assets/images/css-sprite-combined.png') -840px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.redcar-sprite-car_16 {
    background: url('./_assets/images/css-sprite-combined.png') -900px -0px;
    width: 60px;
    height: 60px;
    display: inline-block;
}

.vehicle-tooltip {
    font-weight: bold;
    font-size: 11px;
}
.leaflet-routing-geocoder-result{
    z-index:  10000!important;
}